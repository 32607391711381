import { Flex, Image, Modal, Paper, Text, Tooltip } from "@mantine/core";
import { handleDownload } from "helpers/download";
import { downloadIcon } from "helpers/images";
import React from "react";
import { singledownloadView } from "redux/service/essentialService/essentialService";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "helpers/Golbal";

const ImagePopup = ({ opened, onClose, src, imageName, imageId, type }) => {
  const dispatch = useDispatch();
  const handleDownloads = async (name, ext, id, type) => {
    try {
      const response = await dispatch(
        singledownloadView({ id, name: name, type: type, ext: ext })
      ).unwrap();
    } catch (error) {
      notify(error);
    }
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        title={
          <div>
           
            <Flex justify="start" direction="row">
              View Image

              
              {imageName && (
                <Tooltip label={"Download"}>
                  <Image
                    className="pointer"
                    style={{ marginLeft: "10px" }}
                    onClick={() =>
                      handleDownloads(
                        `${process.env.REACT_APP_API_IMAGE_ENDPOINT}${imageName}`,
                        imageName?.split(".")?.[0],
                        imageId,
                        type
                      )
                    }
                    width={12}
                    src={downloadIcon}
                  />
                </Tooltip>
              )}
            </Flex>
          </div>
        }
        centered
        size="lg"
        withCloseButton={true}
      >
      
        <Flex justify="start" direction="row"></Flex>
        
        <Paper>
          <Flex justify="center" direction="row">
            <Image
              className="pointer"
              fit="contain"
              height={"70vh"}
              src={src}
            />
          </Flex>
        </Paper>
      </Modal>
    </>
  );
};

export default ImagePopup;
