import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Flex, Modal } from "@mantine/core";
import AdressField from "components/react-hook-form/AdressField";
import CheckboxField from "components/react-hook-form/CheckboxField";
import TextField from "components/react-hook-form/TextField";
import ToggleField from "components/react-hook-form/ToggleField";
import { AddressFormater, notify } from "helpers/Golbal";
import { addressSchema } from "helpers/validate";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  customerAddressCreate,
  customerAddressUpdate,
} from "redux/service/menuService/customerService/addressService";

const AddressPopup = ({
  opened,
  close,
  loading,
  data,
  customerId,
  billingListPage,
  shippingListPage,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: {isSubmitting},
  } = useForm({
    defaultValues: data,
    resolver: yupResolver(addressSchema),
    mode: "onSubmit",
  });
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const same_as_billing = watch("shipping_same_billing");
  const billing = watch("billing");
  const shipping = watch("shipping");

  useEffect(() => {
    setValue("billing", true);
    setValue("shipping", true);
    if (data?.id) {
      setShow(true);
      setValue("billing", false);
      setValue("shipping", false);
    }
    if (!data?.id) setValue("shipping_same_billing", "Yes");
  }, []);
  useEffect(() => {
    if (billing && shipping) {
      setValue("shipping_same_billing", "Yes");
    }
  }, [billing, shipping]);
  // notify({ message: "unable to fetch the postal code please change the address ", success: false })
  const handleFormSumbit = async (formData) => {
   
    const bformatedAddress = AddressFormater(formData.address);
    const sformatedAddress =
      same_as_billing === "No" ? AddressFormater(formData.saddress) : [];
      if (bformatedAddress?.postal_code=== null || (same_as_billing === "No" && sformatedAddress?.postalcode === null)) {
        notify({ success: false, message: "Postal code is missing. Please check the addresses." });
        return;
        }
    const formValues = {
      customerId: customerId,
      address: bformatedAddress || formData.fulladdress,
      saddress: sformatedAddress || formData.fulladdress,
      sunit: same_as_billing === "No" ? formData.sunit : "",
      unit: formData.unit,
      address_type:
        billing && shipping
          ? "both"
          : billing
          ? "billing"
          : shipping
          ? "shipping"
          : "",
      shipping_same_billing:
        billing && shipping ? formData?.shipping_same_billing : "",
    };
    try {
      if (formData.id) {
        const formvalue = {
          id: formData?.id,
          ...formValues,
          address_type: formData.address_type,
        };
        const response = await dispatch(
          customerAddressUpdate(formvalue)
        ).unwrap();
        notify(response);
      } else {
        const response = await dispatch(
          customerAddressCreate(formValues)
        ).unwrap();
        notify(response);
        close();
      }
      billingListPage();
      shippingListPage();
      close();
    } catch (error) {
      notify(error);
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => close()}
        centered
        size={300}
        title={data?.id ? "Edit Address" : "Create Address"}
      >
        <form onSubmit={handleSubmit(handleFormSumbit)}>
          {show ? (
            ""
          ) : (
            <Flex justify={"start"} gap={"sm"}>
              <CheckboxField
                name="billing"
                label="Billing Address"
                control={control}
              />
              <CheckboxField
                name="shipping"
                label="Shipping Address"
                control={control}
              />
            </Flex>
          )}
          {billing === false && shipping === false && show === false ? (
            ""
          ) : (
            <>
              <AdressField
                name="address"
                control={control}
                required={true}
                label={
                  billing
                    ? "Billing Address"
                    : shipping
                    ? "Shipping Address"
                    : "Address"
                }
                type="text"
              />
              <TextField
                formatCapital={true}
                name="unit"
                control={control}
                label="Unit Number"
                type="text"
              />
            </>
          )}
          {billing && shipping ? (
            <ToggleField
              control={control}
              name="shipping_same_billing"
              label="shipping same as billing"
            />
          ) : (
            ""
          )}
          {billing && shipping
            ? same_as_billing === "No" && (
                <>
                  <AdressField
                    name="saddress"
                    control={control}
                    required={true}
                    label="Shipping Address"
                    type="text"
                  />

                  <TextField
                    formatCapital={true}
                    name="sunit"
                    control={control}
                    label="Unit Number"
                    type="text"
                  />
                </>
              )
            : ""}
          {billing === false && shipping === false && show === false ? (
            ""
          ) : (
            <Flex mt={"md"} justify={"flex-end"} gap={"md"}>
              <Button
                w={120}
                variant="outline"
                type="reset"
                onClick={() => {
                  close();
                  reset();
                }}
              >
                Cancel
              </Button>
              <Button w={120} type="submit" loading={isSubmitting}>
                {data?.id ? "UPDATE" : "ADD"}
              </Button>
            </Flex>
          )}
        </form>
      </Modal>
    </>
  );
};

export default AddressPopup;
