import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localeData from "dayjs/plugin/localeData";

import { lazy } from "react";
import { excelExportIcon, pdfimg } from "./images";

const appName = process.env.REACT_APP_NAME

const appNameFind = appName === "DURHAM" ? true : false

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
const displayDateFormat = "YYYY-MM-DD";

const timeZone = dayjs.tz.guess();
//  const timeZone = dayjs.tz.setDefault("America/Kolkata")
// const timeZoneTwo = dayjs.tz.setDefault("America/Toronto");
const displayDateTimeFormatTwo = "YYYY-MM-DD hh:mm:ss a"; // Canadian date and time format

export const SESSION = {
  TOKEN: "TOKEN",
  ROLE: "ROLE",
  LABEL: "label",
  EMPLOYEEID: "EMPLOYEEID",
  EMPLOYEE_NAME: "EMPLOYEE_NAME",
  REFRESH_TOKEN: "REFRESH_TOKEN",
};
export function getCurrentWinnipegDate() {
  return dayjs().tz("America/Toronto").format("YYYY-MM-DD");
}

export const OPTIONS = {
  statusOption: ["Active", "Inactive"],
  pyamentStatus: ["unpaid", "paid", "partially_paid"],
  paymentOption: [
    ...(appNameFind ? [{ value: "cash", label: "Cash" }] : []),
    { value: "cheque", label: "Cheque" },
    { value: "email", label: "Email/Bank" },
    // { value: "bank", label: "Bank" },
    { value: "unapplied", label: "Unapplied Amt" },
    { value: "overpayment", label: "Over Payment" },
    { value: "card", label: "Credit/Debit" },
    { value: "ctsairpay", label: appName + " Air Pay" },
    { value: "split", label: "Split Payment" },
  ],
  modepaymentOption: [
    ...(appNameFind ? [{ value: "cash", label: "Cash" }] : []),
    { value: "cheque", label: "Cheque" },
    { value: "email", label: "Email/Bank" },
    { value: "card", label: "Credit/Debit" },
    { value: "ctsairpay", label: appName + " Air Pay" },
    { value: "term", label: "Term" },
    { value: "credit_memo", label: "Credit Memo" },
    { value: "terminal", label: "Terminal" },
  ],
  modepaymentCashOption: [
    { value: "cash", label: "Cash" },
    { value: "cheque", label: "Cheque" },
    { value: "email", label: "Email/Bank" },
    { value: "card", label: "Credit/Debit" },
    { value: "ctsairpay", label: appName + " Air Pay" },
    { value: "term", label: "Term" },
    { value: "credit_memo", label: "Credit Memo" },
    { value: "terminal", label: "Terminal" },
  ],
  receivableOption: [
    ...(appNameFind ? [{ value: "cash", label: "Cash" }] : []),
    { value: "cheque", label: "Cheque" },
    { value: "email", label: "Email/Bank" },
    { value: "card", label: "Credit/Debit" },
    { value: "ctsairpay", label: appName + " Air Pay" },
    { value: "term", label: "Term" },
    { value: "credit_memo", label: "Credit Memo" },
    { value: "split", label: "Split" },
  ],
  inventoryOption: ["InStock", "OverStock", "LowStock", "OutOfStock"],
  discountOption: [
    { value: "0", label: "Greater Than 0%" },
    { value: "20", label: "Greater Than 20%" },
    { value: "40", label: "Greater Than 40%" },
    { value: "60", label: "Greater Than 60%" },
    { value: "80", label: "Greater Than 80%" },
  ],
  refundOption: [
    ...(appNameFind ? [{ value: "cash", label: "Cash" }] : []),
    { value: "cheque", label: "Cheque" },
    { value: "card", label: "Credit/Debit" },
    { value: "email", label: "Email/Bank" },
  ],
  salesTransactionOption: [
    { value: "sales", label: "Order" },
    { value: "return", label: "Return/Refund" },
    { value: "cancel", label: "Cancel Order" },
    { value: "receivable", label: "Receivable" }
    // { value: "backorder", label: "Backorder" },
  ],
  possalesOption: [
    { value: "sales", label: "Order" },
    { value: "return", label: "Return/Refund" },
  ],
  salesOrderTransactionOption: [
    { value: "sales", label: "Order" },
    { value: "return", label: "Return/Refund" },
    { value: "cancel", label: "Cancel Order" },
    { value: "receivable", label: "Receivable" },
  ],
  salesReportOption: [
    { value: "product", label: "Product" },
    { value: "order", label: "Order" },
  ],
  orderstatusOption: [
    { value: "order", label: "Order" },
    { value: "backorder", label: "Back Order" },
    { value: "", label: "All" },
  ],
  paymentstatusOption: [
    { value: "paid", label: "Paid" },
    { value: "unpaid", label: "Unpaid" },
    { value: "partially_paid", label: "Partially Paid" },
    { value: "", label: "All" },
  ],
  storeOption: ["Store", "Store & Web"],
  orderOption: [
    { value: "Pending", label: "Pending" },
    { value: "Order_Preparation", label: "Order Preparation" },
    { value: "Generate_packing_slip", label: "Generate packing slip" },
    { value: "Waiting_To_Dispatch", label: "Waiting To Dispatch" },
    { value: "Ready_to_pickup", label: "Ready To Pickup" },
    { value: "Intransit", label: "Intransit" },
    { value: "Partially_Processed", label: "Backorder" },
    { value: "partially_completed", label: "Partially Completed" },
    { value: "Completed", label: "Completed" },
    { value: "Partially_Refunded", label: "Partially Refunded" },
    { value: "Refunded", label: "Refunded" },
    { value: "partially_returned", label: "Partially Returned" },
    { value: "Returned", label: "Returned" },
    { value: "Partially_Cancelled", label: "Partially Cancelled" },
    { value: "Cancelled", label: "Cancelled" },
  ],
  productStock: ["Product InStock", "Product OutStock"],
  productStatus: ["LIVE", "TEMP", "REMOVED"],
  genderOptions: ["Male", "Female"],
  orderTypeOptions: ["Regular Orders", "Custom Orders", "In House"],
  deliveryType: [
    { label: "Pickup", value: "pickup" },
    { label: "Delivery", value: "delivery" },
  ],
  shippingMethod: [
    { label: "Store pickup", value: "Store pickup" },
    { label: appName + " Delivery", value: "CTS Delivery" },
    { label: "Direct Shipping", value: "Direct Shipping" },
  ],
  temporaryTerm: [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" }
  ],
  // pending','processed','manifest_generated','pickup_scheduled','delivered','Intransit'
  loomisFilter: [
    {label: "Pending", value: "pending"},
    {label: "Processed", value: "processed"},
    {label: "Manifest Generated", value: "manifest_generated"},
    {label: "Pickup Scheduled", value: "pickup_scheduled"},
    {label: "Delivered", value: "delivered"},
    {label: "Intransit", value: "Intransit"},
  ]

};

export const notify = (props) => {
  const errorObj = props.message;
  switch (props.success) {
    case true:
      notifications.show({
        id: "sucess",
        withCloseButton: true,
        autoClose: 3000,
        message: `${props.message}`,
        color: "green",
        className: "my-notification-class",
        loading: false,
      });
      break;
    case "warning":
      notifications.show({
        withCloseButton: true,
        autoClose: 3000,
        message: `${props.message}`,
        color: "yellow",
        className: "my-notification-class",
        loading: false,
      });
      break;
    case false:
      if (typeof errorObj === "object") {
        for (const key in errorObj) {
          notifications.show({
            withCloseButton: true,
            autoClose: 3000,
            message: errorObj[key][0],
            color: "red",
            className: "my-notification-class",
          });
        }
      } else {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          message: errorObj,
          color: "red",
          className: "my-notification-class",
        });
      }
      break;
    default:
      notifications.show({
        id: "sucess",
        withCloseButton: true,
        autoClose: 3000,
        message: "Something Went Wrong",
        color: "red",
        className: "my-notification-class",
        loading: false,
      });
      break;
  }
};
export const notifyTwo = (props) => {
  const errors = props.error

    if(props.status === 400) {
      if(Array?.isArray(errors?.product) && errors?.product?.length > 0) {
    
        errors?.product?.forEach((error) => {
         
          notifications.show({
            withCloseButton: true,
            autoClose: 3000,
            message: `${error}`,
            color: "red",
            className: "my-notification-class",
            loading: false,
          });
         
          });
      } else {
        notifications.show({
          id: "sucess",
          withCloseButton: true,
          autoClose: 3000,
          message: "Something Went Wrong",
          color: "red",
          className: "my-notification-class",
          loading: false,
        });
      }
    } else {
      notifications.show({
        id: "sucess",
        withCloseButton: true,
        autoClose: 3000,
        message: "Something Went Wrong",
        color: "red",
        className: "my-notification-class",
        loading: false,
      });
    }
  
};

export const AddressFormater = (place) => {
  let country,
    province,
    city,
    line1,
    route,
    postal_code = null;
  if (place?.address_components !== undefined) {
    let addrComp = place.address_components;
    for (let i = 0; i < addrComp.length; ++i) {
      var typ = addrComp[i].types;
      if (compIsType(typ, "administrative_area_level_1"))
        province = addrComp[i].long_name; //store the province
      else if (
        compIsType(typ, "locality") ||
        compIsType(typ, "administrative_area_level_3")
      )
        city = addrComp[i].long_name; //store the city
      else if (compIsType(typ, "route"))
        route = addrComp[i].long_name; //store the street
      else if (compIsType(typ, "street_number"))
        line1 = addrComp[i].long_name; //store the street number
      else if (compIsType(typ, "country"))
        country = addrComp[i].long_name; //store the country
      else if (compIsType(typ, "postal_code"))
        postal_code = addrComp[i].long_name; //store the postal_code
      if (
        province != null &&
        city != null &&
        country != null &&
        postal_code != null &&
        line1 != null &&
        route != null
      )
        break;
    }
    return {
      address: place.formatted_address,
      // country: country,
      province: province,
      city: city,
      postal_code: postal_code,
      // street_number: line1,
      street: line1 + route,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
  }
};

export const AddressStoreFormater = (place) => {
  let country,
    province,
    city,
    line1,
    route,
    postal_code = null;
  if (place?.address_components !== undefined) {
    let addrComp = place.address_components;
    for (let i = 0; i < addrComp.length; ++i) {
      var typ = addrComp[i].types;
      console.log(typ)
      if (compIsType(typ, "administrative_area_level_1"))
        province = addrComp[i].long_name; //store the province
      else if (
        compIsType(typ, "locality") ||
        compIsType(typ, "administrative_area_level_3")
      )
        city = addrComp[i].long_name; //store the city
      else if (compIsType(typ, "route"))
        route = addrComp[i].long_name; //store the street
      else if (compIsType(typ, "street_number"))
        line1 = addrComp[i].long_name; //store the street number
      else if (compIsType(typ, "country"))
        country = addrComp[i].long_name; //store the country
      else if (compIsType(typ, "postal_code"))
      
        postal_code = addrComp[i].long_name; //store the postal_code
      if (
        province != null &&
        city != null &&
        country != null &&
        postal_code != null &&
        line1 != null &&
        route != null
      )
        break;
    }
    return {
      store_address: place.formatted_address,
      // country: country,
      store_street: province,
      store_city: city,
      store_postal: postal_code,
      // street_number: line1,
      store_street: line1 + route,
      store_latitude: place.geometry.location.lat(),
      store_longitude: place.geometry.location.lng(),
    };
  }
};

export const compIsType = (t, s) => {
  for (let z = 0; z < t.length; ++z) if (t[z] == s) return true;
  return false;
};

export const lumberFormula = (details) => {
  const { thick, wide, long, price, fbm, pieces, convert_type } = details;
  switch (convert_type) {
    //Feet FBM To pieces
    case 0:
      return {
        calculateEach: (((thick * wide * long) / 12) * price) / 1000,
        calculatepieces: fbm / ((thick * wide * long) / 12),
        calculateTotal:
          ((((thick * wide * long) / 12) * price) / 1000) *
          (fbm / ((thick * wide * long) / 12)),
      };
    //Inch FBM To pieces
    case 1:
      return {
        calculateEach: (((thick * wide * long) / 12 / 12) * price) / 1000,
        calculatepieces: fbm / ((thick * wide * long) / 12 / 12),
        calculateTotal:
          ((((thick * wide * long) / 12 / 12) * price) / 1000) *
          (fbm / ((thick * wide * long) / 12 / 12)),
      };
    //Feet pieces To FBM
    case 2:
      return {
        calculateEach: (((thick * wide * long) / 12) * price) / 1000,
        calculatepieces: pieces * ((thick * wide * long) / 12),
        calculateTotal:
          ((((thick * wide * long) / 12) * price) / 1000) *
          (pieces * ((thick * wide * long) / 12)),
      };
    // Inch pieces to FBM
    case 3:
      return {
        calculateEach: (((thick * wide * long) / 12 / 12) * price) / 1000,
        calculatepieces: pieces * ((thick * wide * long) / 12 / 12),
        calculateTotal:
          ((((thick * wide * long) / 12 / 12) * price) / 1000) *
          (pieces * ((thick * wide * long) / 12 / 12)),
      };
    //Feet FBM to pieces
    default:
      return {
        calculateEach: (((thick * wide * long) / 12) * price) / 1000,
        calculatepieces: fbm / ((thick * wide * long) / 12),
        calculateTotal:
          ((((thick * wide * long) / 12 / 12) * price) / 1000) *
          ((pieces * (thick * wide * long)) / 12),
      };
  }
};

export const CheckYesNo = (value) => {
  if (value === true) {
    return "yes";
  } else {
    return "no";
  }
};

export const convertDate = (date) => {
  date = dayjs(date).tz(timeZone).format(displayDateFormat);
  return date;
};

export const convertDateCredit = (date) => {
  const timestamp = date;
  const dateFinal = new Date(timestamp)?.toISOString()?.split('T')[0];
  return dateFinal
};
export const defaultDateConverter = (date) => {

  date = dayjs(date).tz(timeZone).format(displayDateFormat);
  return date;
};
export const defaultDateConverterTwo = (date) => {
  date = dayjs(date).tz(timeZone).format(displayDateTimeFormatTwo);
  return date;
};
export const latestCoverter = (date) => {
   if (date === undefined || date === null) {
    return ''; 
    }
  date = dayjs(date).format(displayDateFormat);
  return date;
};

export const convertDateTimeTwo = (date) => {
  date = dayjs(date).tz(timeZone).format(displayDateTimeFormatTwo);
  return date;
};
export const convertDateTimeThree = (date) => {
  date = dayjs(date).tz(timeZone).format(displayDateFormat);
  return date;
};

// export const formateDate = (dateString) => {
//   console.log(dateString)
//   const formattedDate = dayjs(dateString)
//     .tz(timeZone)
//     .toDate(displayDateFormat);
//   return formattedDate;
// };
export const formateDate = (dateString) => {
  // Check if the dateString is valid
  const isValidDate = dayjs(dateString).isValid();

  if (!isValidDate) {
    return null; // or return a default value
  }
  const formattedDate = dayjs(dateString)
    .tz(timeZone)
    .toDate(displayDateFormat); // Use format instead of toDate for a string output

  return formattedDate;
};
// export const formatCurrencyComma = (amount) => {
//   return `$ ${Number(amount ?? 0).toLocaleString("en-IN", {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   })}`;
// };

export const formatCurrencyComma = (amount) => {
  const num = Number(amount);
  return `$ ${isNaN(num)
    ? "0.00"
    : num?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    }`;
};

export const formatCurrencyMinusComma = (amount) => {
  const num = Number(amount);
  return `$ -${isNaN(num)
    ? "0.00"
    : num?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    }`;
};
export const formatNumberComma = (number) => {
  return `${Number(number ?? 0)?.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  })}`;
};

export const formatBreadScrumbTitle = (text) => {
  const spacedText = text?.replace(/([A-Z])/g, " $1")?.trim();
  const formattedText = `${spacedText[0]?.toUpperCase()}${spacedText?.substring(
    1
  )}`;
  return formattedText;
};

export const formatStatus = (text) => {
  return `${text[0]?.toUpperCase()}${text?.substring(1)}`?.split("_")?.join(" ");
};

export const formatPayStatus = (text) => {
  return text?.split(",")?.map(
    (segment) =>
      segment?.replace(/([A-Z])/g, " $1") // Add space before each capital letter
        .replace(/_/g, " ") // Replace underscores with spaces
        .trim() // Remove leading and trailing spaces
        .split(" ") // Split by spaces
        .map(
          (word) => word.charAt(0)?.toUpperCase() + word.slice(1)?.toLowerCase() // Capitalize first letter and lowercase the rest
        )
        .join(" ") // Join the words back with a space
  )
    .join(", "); // Join the segments back with a comma and space
};

export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    try {
      const component = await componentImport();
      return component;
    } catch {
      return window.location.reload();
    }
  });

const convertTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getUTCFullYear();
  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  //Convert hours from 24-hour to 12-hour format
  hours = hours % 12 || 12; // Adjust 0 hour to be 12
  hours = String(hours).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}:${period}`;
};

export const convertTimeDate = (date) => {
  const timestamp = date;
  const formattedTimestamp = convertTimestamp(timestamp);
  return formattedTimestamp;
};
export const convertTimeCanadian = (date) => {
  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) {
    return "-";
  }

  // Convert to Toronto time and format
  return parsedDate.tz("America/Toronto").format("YYYY-MM-DD hh:mm:ss a");
};

export const convertDateOnly = (date) => {
  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) {
    return "-";
  }

  // Convert to Toronto time and format
  return parsedDate.tz("America/Toronto").format("YYYY-MM-DD");
};


export const convertTimeCanadianPos = (date) => {
  if (date === undefined) {
    return '-'
  } else {
    const parsedDate = dayjs(date)
    return parsedDate.tz("America/Toronto").format("YYYY-MM-DD hh:mm:ss a");
  }
};
export const compareTimeCanadianPos = (date) => {
  if (date === undefined) {
    return '-'
  } else {
    const parsedDate = dayjs(date)
    return parsedDate.tz("America/Toronto").format("YYYY-MM-DD hh:mm a");
  }
};

///
export const formatTimeAMPM = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const formatDateTimenew = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "-";
  }

  // Format the date
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // Format the time
  const formattedTime = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  return `${formattedDate}, ${formattedTime}`;
};

export const makeQueryString = (q) => {
  let params = new URLSearchParams(q);
  let keysForDel = [];
  params.forEach((value, key) => {
    if (value === "") {
      keysForDel.push(key);
    } else if (value === null) {
      keysForDel.push(key);
    } else if (typeof value === "undefined") {
      keysForDel.push(key);
    } else if (typeof value === undefined) {
      keysForDel.push(key);
    }
  });

  keysForDel.forEach((key) => {
    params.delete(key);
  });

  return params.toString();
}

export const formatType = (type) => {
  if (!type) return type;

  // Replace underscores with spaces and capitalize each word
  return type
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\b\w/g, char => char.toUpperCase());
};

export const arrayDateFormat = (dateArray) => {
  return dateArray?.map(date => dayjs(date)?.format('YYYY-MM-DD'));
};


export const dateDifferenceInDays = (date1Str, date2Str) => {
  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export const getFilePreviewSrc = (fileName) => {
  const fileExtension = fileName?.split('.')?.pop()?.toLowerCase();
  const apiEndpoint = process.env.REACT_APP_API_IMAGE_ENDPOINT;

  switch (fileExtension) {
    case 'pdf':
      return pdfimg;
    case 'xls':
    case 'xlsx':
      return excelExportIcon;
    case 'jpeg':
    case 'jpg':
    case 'png':
      return `${apiEndpoint}${fileName}`;
    default:
      return fileName;
    // return fileName && URL.createObjectURL(fileName);
  }
};

export const pickupDateformat = (date) => {
  const formattedDate = dayjs(date).format('YYYYMMDD');
  return formattedDate
}

export const pickuplistDate = (date) => {
  const formattedDate = dayjs(date).format('MM-DD-YYYY');
  return formattedDate
}

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };