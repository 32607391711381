import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Input,
  Loader,
  Paper,
  Table,
  Tabs,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import ImagePopup from "components/popups/ImagePopup";
import MultipleDocumentPopup from "components/popups/MultipleDocumentPopup";
import SelectFieldPopup from "components/popups/SelectFieldPopup";
import SendEmailPopup from "components/popups/SendEmailPopup";
import NumberField from "components/react-hook-form/NumberField";
import TextAreaField from "components/react-hook-form/TextAreaField";
import TextField from "components/react-hook-form/TextField";
import TableData from "components/table";
import {
  convertTimeCanadian,
  convertTimeCanadianPos,
  convertTimeDate,
  convertTimeZone,
  formatCurrencyComma,
  formatNumberComma,
  formatStatus,
  notify,
} from "helpers/Golbal";
import {
  addIcon,
  docLogo,
  downloadIcon,
  editIcon,
  minIcon,
  printIcon,
  sendIcon,
  uploadIcon,
} from "helpers/images";
import { orderPreparationSchema } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  downloadView,
  sendEmail,
} from "redux/service/essentialService/essentialService";
import { handleDownload, handlePrintDocument } from "helpers/download";
import { customerShippingList } from "redux/service/menuService/customerService/addressService";
import {
  backOrderDispatch,
  backOrderPreparation,
  backOrderProcessing,
  createOrderCompleted,
  createOrderDispatch,
  createOrderPreparation,
  createOrderProcess,
  orderShipingAdressChange,
} from "redux/service/menuService/orderService";
import tabStyle from "styles/style-Api/tabStyle";
import DatePopup from "components/popups/DatePopup";
import Datepickers from "components/react-hook-form/DatePickers";

const appName = process.env.REACT_APP_NAME

const OrderViewSection = ({
  initialValues,
  options,
  notVisable,
  title,
  label,
  status,
  change_qty_key,
  change_qty_name,
  view_invoice,
  back_quantity,
  substatus,
  printValue,
  enable_tax,
  customerIdlink,
  complete_status = false,
  view_order_detail = false,
  orderstatus,
  val,
  notshow,
  cancelinsert
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = tabStyle();
  const queryParams = new URLSearchParams(location.search);
  const statuslocation = queryParams.get("status");
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...initialValues,
      comments: statuslocation === "CompleteOrders" ? initialValues?.special_comments : initialValues?.comments
    },
    resolver: yupResolver(orderPreparationSchema),
    mode: "onSubmit",
  });


  const paramsId = useParams();
  const { shippingAddress, downloadloading } = useSelector((state) => {
    return {
      shippingAddress: state.customerAddress.customerShippingListRes.data,
      downloadloading: state.essential.downloadViewRes.isFetching,
    };
  });


  const selectedRows = watch("selectedRows");
  const setDisable = selectedRows?.selectedRows?.map((item) => item.id);
  const { fields } = useFieldArray({
    control,
    name: "details",
  });
  const [documentPopup, setDocumentPopup] = useState(false);
  const [editAddressModel, setEditAddressModel] = useState({
    address_type: "",
    model: false,
  });
  const [sendEmailModel, setSendEmailModel] = useState({
    model: false,
    data: null,
  });

  const address = initialValues?.billingaddress?.address;
  const unit = initialValues?.billingaddress?.unit;
  const displayAddress = unit ? `Unit No : ${unit}, ${address} ` : address;

  const productdetails = watch("selectedRows")?.allSelected
    ? watch("details")
    : watch("details")?.filter((values) => {
      return watch("selectedRows")?.selectedRows?.some(
        (value) => value.verify_id === values.verify_id
      );
    });
  const totalQuantity = initialValues?.details
    ?.map((data) => {
      return data?.[change_qty_key ?? "quantity"];
    })
    ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0.0);
  const totalQuantityAll = productdetails
    ?.map((data) => {
      return data?.[change_qty_key ?? "quantity"];
    })
    ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0.0);
  const totalWeight = initialValues?.details
    // ?.map((data) => {
    //   return data?.weight * data?.[change_qty_key ?? "quantity"];
    // })
    ?.reduce((accumulator, currentValue) => {
      const amount =
        (currentValue?.weight ?? 0) *
        currentValue?.[change_qty_key ?? "quantity"];
      return accumulator + amount;
    }, 0.0);

  const totalWeight_preparation = watch("details")?.reduce(
    (accumulator, currentValue) => {
      let selectData =
        watch("selectedRows")?.selectedCount &&
        watch("selectedRows").selectedRows;
      if (selectData) {
        var data = selectData.filter(function (e) {
          return e.product_id === currentValue.product_id;
        });
        if (data.length > 0) {
          if (
            status === "order_preparation" ||
            status === "back_order_preparation"
          ) {
            const preparation_weight =
              currentValue?.weight * currentValue?.verify_qty || 0;
            return accumulator + preparation_weight;
          }
          if (
            status === "generate_packing_slip" ||
            status === "back_order_dispatch"
          ) {
            const preparation_weight =
              currentValue?.weight * currentValue?.delivery_qty || 0;
            return accumulator + preparation_weight;
          }
          if (status === "order_complete") {
            const preparation_weight =
              currentValue?.weight * currentValue?.complete_qty || 0;
            return accumulator + preparation_weight;
          }
        } else {
          return accumulator;
        }
      } else {
        return 0;
      }
    },
    0.0
  );

  const totalWeightAll = productdetails
    // ?.map((data) => {
    //   return data?.weight * data?.[change_qty_key ?? "quantity"];
    // })
    ?.reduce((accumulator, currentValue) => {
      const amount = currentValue?.weight ?? 0;
      // *
      // currentValue?.[change_qty_key ?? "quantity"];
      return accumulator + amount;
    }, 0.0);
  const manage_tax = options?.tax?.filter(
    (data) => watch("tax_id") === data?.value
  )[0]?.tax_amount;

  const [isModalOpen, setIsModalOpen] = useState({
    model: false,
    data: null,
  });
  const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState({
    model: false,
    data: null,
  });

  const handleSignatureOpen = (data) => {
    setIsModalOpen({
      ...isModalOpen,
      model: true,
      data: data,
    });
  }
  const handleImageClick = (data) => {
    setIsModalOpen({
      ...isModalOpen,
      model: true,
      data: data,
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen({
      ...isModalOpen,
      model: false,
      data: null,
    });
  };



  const handleFormSumbit = async (data) => {
    const formValues = {
      // ...data,
      id: data?.id,
      order_id: data?.order_id ?? data?.id,
      comments: data?.comments,
      verify_user: data?.verify_user,
      delivery_date: data?.delivery_date,
      driver: data?.driver,
      documents: data?.documents,
      // shippingaddress: data?.shippingaddress,
      // order_number: data.order_number,
      // customer_id: data.customer_id,
      // website_id: data.website_id,
      // status: data.status,
      // payment_status: data.payment_status,
      details: data.details.map((values) => {
        return {
          // ...values,
          id: values.id,
          product_id: values.product_id,
          change_qty: selectedRows.selectedRows.some(
            (value) => value.verify_id === values.verify_id
          )
            ? values?.[change_qty_key]
            : 0,
        };
      }),
      // back_orders: data.details?.filter((values) => {
      //   return !selectedRows.selectedRows.some(
      //     (value) => value.verify_id === values.verify_id
      //   );
      // }),
      // check: selectedRows.allSelected ? 0 : 1,
    };
    try {
      if (status === "order_preparation") {
        const response = await dispatch(
          createOrderPreparation(formValues)
        ).unwrap();
        notify(response);
        navigate("/menu/order/order-ready-go");
      } else if (status === "order_process") {
        const response = await dispatch(
          createOrderProcess(formValues)
        ).unwrap();
        notify(response);
        navigate("/menu/order/list");
      } else if (status === "generate_packing_slip") {
        const response = await dispatch(
          createOrderDispatch(formValues)
        ).unwrap();
        notify(response);
        handleDownloads(
          response.data.id,
          "packingslip",
          "download",
          `-${initialValues?.order_no}`
        );
        navigate("/menu/order/order-delivery-status");
      } else if (status === "order_complete") {
        const response = await dispatch(
          createOrderCompleted(formValues)
        ).unwrap();
        notify(response);
        navigate("/menu/order/complete-order");
      } else if (status === "back_order_preparation") {
        const response = await dispatch(
          backOrderPreparation(formValues)
        ).unwrap();
        notify(response);
        navigate("/menu/order/back-order-list");
      } else if (status === "back_order_dispatch") {
        const response = await dispatch(backOrderDispatch(formValues)).unwrap();
        notify(response);
        navigate("/menu/order/order-delivery-status");
        handleDownloads(
          response.data.id,
          "packingslip",
          "download",
          `-${initialValues?.order_no}`
        );
      } else if (status === "back_order_processing") {
        const response = await dispatch(
          backOrderProcessing(formValues)
        ).unwrap();
        notify(response);
        navigate("/menu/order/list");
      }
    } catch (error) {
      notify(error);
    }
  };

  const handleChangeAddress = async (data) => {
    try {
      const formValues = {
        customer_id: initialValues.customer_id,
        order_id: initialValues.order_id,
        address: data.shipping_id,
      };
      const response = await dispatch(
        orderShipingAdressChange(formValues)
      ).unwrap();
      setEditAddressModel({
        model: false,
        address_type: "shipping",
      });
      const createObj = {
        ...response.data?.shipping,
      };
      setValue("shippingaddress", createObj);
      notify(response);
    } catch (error) {
      notify(error);
    }
  };
  const handleChange = (data) => {
    setValue("selectedRows", data);
    setError("selectedRows", { type: "custom", message: null });
  };

  const handleDownloads = async (id, name, type, number) => {
    try {
      await dispatch(
        downloadView({ id, name: name, type: type, number })
      ).unwrap();
    } catch (error) {
      notify(error);
    }
  };

  const handleSendEmail = async (data) => {
    try {
      const response = await dispatch(
        sendEmail({
          id: title === "Cancel Order" ? data?.id : data.customer_id,
          name:
            // status === "order_preparation"
            //   ? "orderpreparation"
            //   :
            title === "Generate Packing Slip"
              ? "packingslip"
              : title === "Cancel Order"
                ? "cancelorder"
                :  printValue ?? initialValues?.pdf_name ?? "invoice",
          data,
        })
      ).unwrap();
      notify(response);
      setSendEmailModel({
        model: false,
        data: null,
      });
    } catch (error) {
      notify(error);
    }
  };
  // This Function For Increasing Decreasing the Quantity
  const handleQuantiy = (index, process, unit) => {
    const quantity_no = watch(`details.${index}.${change_qty_key}`);
    if (process === "min") {
      setValue(`details.${index}.${change_qty_key}`, quantity_no - 1);
    } else {
      setValue(`details.${index}.${change_qty_key}`, quantity_no + 1);
    }
    // handleCommonTotal(index, unit);
  };

  // This Function for Total For iNDIVIDUAL rows
  // const handleCommonTotal = (index, unit) => {
  //   setValue(
  //     `details.${index}.amount`,
  //     (watch(`details.${index}.${change_qty_key}`) === ""
  //       ? 0
  //       : watch(`details.${index}.${change_qty_key}`)) * unit
  //   );
  //   handleFinalTotal();
  // };
  // This Function For calculate The Total and Tax
  // const handleFinalTotal = () => {
  //   setValue(
  //     "sub_total",
  //     productdetails
  //       ?.map((data) => {
  //         return data?.amount;
  //       })
  //       ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0.0)
  //   );
  //   setValue("total_tax", (watch("sub_total") * manage_tax).toFixed(2));
  //   setValue("grand_total", watch("sub_total") + parseInt(watch("total_tax")));
  // };

  // handle Documnet
  const handleUploadDocments = (data) => {
    setValue("documents", data.documents);
    setDocumentPopup(false);
  };
  const handleDateFormSubmit = async (data) => {
    try {
      const formValues = {
        order_id: initialValues.order_id,
        delivery_date: data.delivery_date,
      };
      const response = await dispatch(
        orderShipingAdressChange(formValues)
      ).unwrap();
      setValue("delivery_date", response?.data?.delivery_date);
      setIsDeliveryModalOpen({
        model: false,
      });
      notify(response);
    } catch (error) {
      notify(error);
    }
  };
  const shippingOption = shippingAddress?.map((data) => {
    return {
      label: data.address.address,
      value: data.id,
    };
  });
  const columns = [
    {
      name: "Item#",
      selector: (row, index) => <div>{index + 1}</div>,
      width: "6rem",
    },
    {
      name: appName + " SKU",
      selector: (row) => <div>{row.product_sku}</div>,
      width: "6rem",
    },
    {
      name: "Product Name",
      selector: (row) => (
        <Tooltip label={row.product_name}>
          <Text>{row.product_name}</Text>
        </Tooltip>
      ),
      width: "18rem",
    },
    {
      name: "Insert",
      selector: (row) => <div>{row.floor_name}</div>,
      maxWidth: "8rem",
      omit: title === "Cancel Order"
    },
    {
      name: "Status",
      selector: (row) => (
        <div style={{ textTransform: "capitalize" }}>{row?.display_status}</div>
      ),
      width: "9rem",
      omit: view_order_detail !== true,
    },

    {
      name: "Unit Type",
      selector: (row) => <div>{row.unit_type}</div>,
      width: "6rem",
    },
    {
      name: "Quantity",
      selector: (row) => (
        <div>
          <div>
            {(title === "Order Preparation" &&
              initialValues?.status === "Partially_Refunded") ||
              (title === "Generate Packing Slip" &&
                initialValues?.return_status === "Partially_refunded")
              ? formatNumberComma(row?.quantity_to_process)
              : formatNumberComma(row.quantity)}
          </div>
        </div>
      ),
      width: "6rem",
    },
    {
      name: "Qty to Process",
      selector: (row) => (
        <div>{formatNumberComma(row?.quantity_to_process)}</div>
      ),
      width: "9rem",
      omit: view_order_detail !== true,
    },
    {
      name: "Back Quantity",
      selector: (row) => <div>{formatNumberComma(row.back_quantity)}</div>,
      maxWidth: "4rem",
      omit: !back_quantity,
    },
    {
      name: "Verify Quantity",
      selector: (row) => <div>{formatNumberComma(row.verify_qty)}</div>,
      maxWidth: "4rem",
      omit: status !== "generate_packing_slip",
    },
    {
      name: "Ship Quantity",
      selector: (row) => <div>{formatNumberComma(row.delivery_quantity)}</div>,
      maxWidth: "4rem",
      omit: status !== "order_complete",
    },
    {
      name: "Delivery Quantity",
      selector: (row) => <div>{formatNumberComma(row.delivery_quantity)}</div>,
      maxWidth: "4rem",
      omit: status !== "delivery_complete",
    },
    {
      name: "Complete Quantity",
      selector: (row) => <div>{formatNumberComma(row?.complete_quantity)}</div>,
      maxWidth: "4rem",
      omit: status !== "delivery_complete",
    },
    {
      name: change_qty_name,
      selector: (row, index) => (
        <>
          {setDisable?.includes(row.id) && (
            <Flex gap={"xs"} align={"center"}>
              <Image
                className="pointer"
                src={minIcon}
                onClick={() =>
                  watch(`details.${index}.${change_qty_key}`) > 1 &&
                  handleQuantiy(index, "min", row.unit_price)
                }
                maw={20}
              />
              <div
                onBlur={() => {
                  watch(`details.${index}.${change_qty_key}`) === "" &&
                    setValue(`details.${index}.${change_qty_key}`, 1);
                }}
              >
                <NumberField
                  miw={35}
                  maw={40}
                  max={row?.max_qty ?? row?.[change_qty_key]}
                  min={1}
                  variant={"unstyled"}
                  name={`details.${index}.${change_qty_key}`}
                  control={control}
                />
              </div>
              <Image
                className="pointer"
                src={addIcon}
                maw={20}
                onClick={() =>
                  watch(`details.${index}.${change_qty_key}`) <
                  (row?.max_qty ?? row?.[change_qty_key]) &&
                  handleQuantiy(index, "add", row.unit_price)
                }
              />
            </Flex>
          )}
        </>
      ),
      center: true,
      width: "6.9rem",
      omit: !notVisable,
    },
    {
      name: "Price",
      selector: (row) => <div>{formatCurrencyComma(row?.price)}</div>,
      maxWidth: "4rem",
    },
    {
      name: "Amount",
      selector: (row, index) => (
        <>
          {formatCurrencyComma(watch(`details.${index}.amount`))}
          <NumberField
            name={`details.${index}.amount`}
            control={control}
            display={"none"}
          />
        </>
      ),
      width: "7rem",
      omit: !enable_tax,
    },
  ];
  const returncolumns = [
    {
      name: "Item#",
      selector: (row, index) => <div>{index + 1}</div>,
      maxWidth: "4rem",
    },
    {
      name: appName + " SKU",
      selector: (row) => <div>{row.product_sku}</div>,
      maxWidth: "4rem",
    },
    {
      name: "Product Name",
      selector: (row) => <div>{row.product_name} </div>,
      maxWidth: "10rem",
    },
    {
      name: "Insert",
      selector: (row) => <div>{row.floor_name}</div>,
      maxWidth: "4rem",

    },
    {
      name: "Status",
      selector: (row) => (
        <div style={{ textTransform: "capitalize" }}>{row?.display_status}</div>
      ),
      width: "9rem",
      omit: view_order_detail !== true,
    },
    {
      name: "Unit Type",
      selector: (row) => <div>{row.unit_type}</div>,
      maxWidth: "4rem",
    },
    {
      name: "Quantity",
      selector: (row) => (
        <div>
          <div>
            {(title === "Order Preparation" &&
              initialValues?.status === "Partially_Refunded") ||
              (title === "Generate Packing Slip" &&
                initialValues?.return_status === "Partially_refunded")
              ? formatNumberComma(row?.quantity_to_process)
              : formatNumberComma(row.quantity)}
          </div>
        </div>
      ),
      maxWidth: "4rem",
    },
    {
      name: "Qty to Process",
      selector: (row) => (
        <div>{formatNumberComma(row?.quantity_to_process)}</div>
      ),
      width: "9rem",
      omit: view_order_detail !== true,
    },
    {
      name: "Back Quantity",
      selector: (row) => <div>{formatNumberComma(row.back_quantity)}</div>,
      maxWidth: "4rem",
      omit: !back_quantity,
    },
    {
      name: "Verify Quantity",
      selector: (row) => <div>{formatNumberComma(row.verify_qty)}</div>,
      maxWidth: "4rem",
      omit: status !== "generate_packing_slip",
    },
    {
      name: "Ship Quantity",
      selector: (row) => <div>{formatNumberComma(row.delivery_quantity)}</div>,
      maxWidth: "4rem",
      omit: status !== "order_complete",
    },
    {
      name: "Delivery Quantity",
      selector: (row) => <div>{formatNumberComma(row.delivery_quantity)}</div>,
      maxWidth: "4rem",
      omit: status !== "delivery_complete",
    },
    {
      name: "Complete Quantity",
      selector: (row) => <div>{formatNumberComma(row?.complete_quantity)}</div>,
      maxWidth: "4rem",
      omit: status !== "delivery_complete",
    },
    {
      name: change_qty_name,
      selector: (row, index) => (
        <>
          {setDisable?.includes(row.id) && (
            <Flex gap={"xs"} align={"center"}>
              <Image
                className="pointer"
                src={minIcon}
                onClick={() =>
                  watch(`details.${index}.${change_qty_key}`) > 1 &&
                  handleQuantiy(index, "min", row.unit_price)
                }
                maw={20}
              />
              <div
                onBlur={() => {
                  watch(`details.${index}.${change_qty_key}`) === "" &&
                    setValue(`details.${index}.${change_qty_key}`, 1);
                }}
              >
                <NumberField
                  miw={35}
                  maw={40}
                  max={row?.max_qty ?? row?.[change_qty_key]}
                  min={1}
                  variant={"unstyled"}
                  name={`details.${index}.${change_qty_key}`}
                  control={control}
                />
              </div>
              <Image
                className="pointer"
                src={addIcon}
                maw={20}
                onClick={() =>
                  watch(`details.${index}.${change_qty_key}`) <
                  (row?.max_qty ?? row?.[change_qty_key]) &&
                  handleQuantiy(index, "add", row.unit_price)
                }
              />
            </Flex>
          )}
        </>
      ),
      center: true,
      width: "6.9rem",
      omit: !notVisable,
    },
    {
      name: "Price",
      selector: (row) => <div>{formatCurrencyComma(row?.price)}</div>,
      maxWidth: "4rem",
    },
    {
      name: "Amount",
      selector: (row, index) => (
        <>
          {formatCurrencyComma(row?.amount)}
          {/* {formatCurrencyComma(watch(`details.${index}.amount`))} */}
          {/* <NumberField
            name={`details.${index}.amount`}
            control={control}
            display={"none"}
          /> */}
        </>
      ),
      maxWidth: "7rem",
      omit: !enable_tax,
    },
  ];

  const essentialApi = (id) => {
    dispatch(customerShippingList(id));
  };

  useEffect(() => {
    if (editAddressModel.model) essentialApi(initialValues?.customer_id);
  }, [editAddressModel.model]);

  return (
    <>
      <form>
        <Paper px={40} py={25}>
          <Flex justify={"space-between"}>
            <Title color="font-color.0" order={4} weight={500}>
              {title}
            </Title>
            {
              // title !== "Cancel Order" && (
              <Flex gap={15}>
                <Image
                  width={15}
                  className="pointer"
                  onClick={() =>
                    setSendEmailModel({
                      model: true,
                      data: {
                        id: initialValues?.id,
                        customer_id: initialValues?.pdf_packingslip_id,
                        customer_name:
                          initialValues?.customer_details.company_name,
                        email: initialValues?.customer_details?.email,
                      },
                    })
                  }
                  src={sendIcon}
                />
                {downloadloading ? (
                  <Loader />
                ) : (
                  <>
                    <Image
                      width={15}
                      className="pointer"

                      onClick={() => {

                        handleDownloads(
                          title === "Cancel Order" ?  initialValues?.id : initialValues?.pdf_packingslip_id,
                          title === "Generate Packing Slip"
                            ? "packingslip"
                            : title === "Cancel Order"
                              ? "cancelorder"
                              : printValue ?? initialValues?.pdf_name ?? "orderconfirm",
                          "print",
                          initialValues?.order_no
                        );
                      }}

                      src={printIcon}
                    />
                    <Image
                      width={15}
                      className="pointer"

                      onClick={() => {
                        handleDownloads(
                          title === "Cancel Order" ?  initialValues?.id : initialValues?.pdf_packingslip_id,
                          title === "Generate Packing Slip"
                            ? "packingslip"
                            : title === "Cancel Order"
                              ? "cancelorder"
                              : printValue ?? initialValues?.pdf_name ?? "orderconfirm",
                          "download",
                          initialValues?.order_no
                        );
                      }}
                      src={downloadIcon}
                    />
                  </>
                )}
              </Flex>
              // )
            }

          </Flex>
          <Grid mt={15}>
            <Grid.Col md={3}>
              <Text
                tt="capitalize"
                mb={10}
                fw={"bold"}
                fz={"lg"}
                color="font-color.3"
              >
                {title === "View Quotation" || title === "View Estimation"
                  ? label.id
                  : "ORDER ID"}
                : {initialValues?.order_no}
              </Text>
              <Flex>
                <Text
                  tt="capitalize"
                  fw={"bold"}
                  fz={"lg"}
                  color="font-color.3"
                >
                  {title === "View Quotation" || title === "View Estimation"
                    ? label?.date
                    : "Order Date"}
                  :
                </Text>
                <Text
                  tt="capitalize"
                  pl={5}
                  fw={"normal"}
                  fz={"lg"}
                  color="font-color.3"
                >
                  {initialValues?.date}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col md={3}>
              <Text
                tt="capitalize"
                mb={10}
                fw={"bold"}
                fz={"lg"}
                color="font-color.3"
              >
                Customer ID: {initialValues?.customer_details?.customer_id}
              </Text>
              <Flex>
                <Text
                  tt="capitalize"
                  fw={"bold"}
                  fz={"lg"}
                  color="font-color.3"
                >
                  {title === "View Quotation" || title === "View Estimation"
                    ? label.takenBy
                    : "Order Taken By"}
                  :
                </Text>
                <Text
                  tt="capitalize"
                  pl={5}
                  fw={"normal"}
                  fz={"lg"}
                  color="font-color.3"
                >
                  {initialValues?.order_taken_by}
                </Text>
              </Flex>
            </Grid.Col>

            <Grid.Col md={3}>
              <Flex mb={10}>
                <Text
                  tt="capitalize"
                  fw={"bold"}
                  fz={"lg"}
                  color="font-color.3"
                >
                  Company Name:
                </Text>
                <Text
                  tt="capitalize"
                  pl={5}
                  fw={"normal"}
                  fz={"lg"}
                  color="font-color.3"
                >
                  {initialValues?.customer_details?.company_name ??
                    initialValues?.customer_details?.name}
                </Text>
              </Flex>
              {title !== "View Quotation" && title !== "View Estimation" && title !== "Cancel Order" && (
                <Flex>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Sales Person:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.sales_person}
                  </Text>
                </Flex>
              )}
            </Grid.Col>

            <Grid.Col md={3}>
              {title === "View Estimation" && (
                <Flex mb={10}>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Estimation Expiry:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.expiry_date}
                  </Text>
                </Flex>
              )}
              {title !== "View Quotation" && title !== "View Estimation" && (
                <Flex mb={10}>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Mode of Payment:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.transaction_type}
                  </Text>
                </Flex>
              )}{title !== "Cancel Order" && (

                <Flex mb={10}>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Customer PO#:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.customer_po}
                  </Text>
                </Flex>
              )
              }

            </Grid.Col>
          </Grid>
          {(!notVisable && title !== "Cancel Order") && (

            <Grid>

              <Grid.Col md={3}>
                <Flex mb={10}>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Received By:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.receive_by}
                  </Text>
                </Flex>
                <Flex mb={10}>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Project Name:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.project_name}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col md={3}>
                <Flex mb={10}>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {title === "View Quotation" || title === "View Estimation"
                      ? label.pacedBy
                      : "Order Placed By(Customer)"}
                    :
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.ps_create_by}
                  </Text>
                </Flex>
                <Flex mb={10}>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Referred By:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.referred_by}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col md={3}>
                <Flex mb={10}>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Onsite Contact:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.onsite_contact}
                  </Text>
                </Flex>
                <Flex>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Order Type:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.order_type}
                  </Text>
                </Flex>
              </Grid.Col>

            </Grid>
          )}


          {initialValues?.shipping_method !== "Store pickup" && title !== "Cancel Order" && (
            <Grid>
              <Grid.Col md={3}>
                <Flex mb={10}>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Ship Via:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.shipvia?.ship_type}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col md={3}>
                <Flex>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Delivery Type:
                  </Text>
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {initialValues?.deliveytype?.type}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col md={3}>
                <Flex>
                  <Text
                    tt="capitalize"
                    fw={"bold"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    Delivery Date:
                  </Text>{" "}
                  <Text
                    tt="capitalize"
                    pl={5}
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {watch("delivery_date")}
                  </Text>
                  {status === "generate_packing_slip" &&
                    initialValues?.shipping_method !== "Store pickup" && (
                      <Image
                        className="pointer"
                        width={10}
                        src={editIcon}
                        onClick={() =>
                          setIsDeliveryModalOpen({
                            ...isDeliveryModalOpen,
                            model: true,
                            name: "delivery_date",
                            label: "Deliver Date",
                          })
                        }
                      />
                    )}
                </Flex>
              </Grid.Col>
            </Grid>
          )}

          <Grid mb={15}>
            <Grid.Col md={4}>
              <Text tt="capitalize" fw={"bold"} fz={"lg"} color="font-color.3">
                Billing To:
              </Text>
              <Text
                tt="capitalize"
                fw={"normal"}
                fz={"lg"}
                color="font-color.3"
              >
                {displayAddress}
              </Text>
            </Grid.Col>
            <Grid.Col md={4}>
              {initialValues?.shipping_method !== "Store pickup" && (
                <>
                  <Flex>
                    <Text
                      tt="capitalize"
                      fw={"bold"}
                      fz={"lg"}
                      color="font-color.3"
                    >
                      Ship To:
                    </Text>
                    {(status === "generate_packing_slip" || status === "delivery_complete") &&
                      initialValues?.shipping_method !== "Store pickup" && (
                        <Image
                          className="pointer"
                          width={10}
                          src={editIcon}
                          onClick={() =>
                            setEditAddressModel({
                              model: true,
                              address_type: "shipping",
                            })
                          }
                        />
                      )}
                  </Flex>

                  <Text
                    tt="capitalize"
                    fw={"normal"}
                    fz={"lg"}
                    color="font-color.3"
                  >
                    {watch("shippingaddress")?.unit && (
                      <>
                        Unit No : {watch("shippingaddress")?.unit} ,{" "}
                        {watch("shippingaddress")?.address}
                      </>
                    )}
                    {!watch("shippingaddress")?.unit &&
                      watch("shippingaddress")?.address}
                  </Text>
                </>
              )}
            </Grid.Col>
            <Grid.Col md={4}>
              <Flex mb={10} justify="flex-end">
                <Text
                  tt="capitalize"
                  fw={"bold"}
                  fz={"lg"}
                  color="font-color.3"
                >
                  Shipping Method :
                </Text>
                <Text
                  tt="capitalize"
                  pl={5}
                  fw={"normal"}
                  fz={"lg"}
                  color="font-color.3"
                >
                  {initialValues?.shipping_method}
                </Text>
              </Flex>
            </Grid.Col>
          </Grid>


          <TableData
            columns={columns}
            data={fields}
            noHeight
            selectableRows={notVisable}
            onSelectedRowsChange={handleChange}
            fixedHeader={true}
            fixedHeaderScrollHeight={"70vh"}
          />

          {notVisable && errors.selectedRows && (
            <Input.Error>{errors.selectedRows.message}</Input.Error>
          )}
          {initialValues?.exchangeorder && (
            <>
              <Flex my={10} justify="flex-start">
                <Text fw={"bold"} fz={"lg"}>
                  Exchange-Return Order:{" "}
                </Text>
                <Text
                  tt="capitalize"
                  fw={"bold"}
                  fz={"lg"}
                  color="font-color.3"
                >
                  {initialValues?.exchangeorder?.return_id}
                </Text>
              </Flex>
              <TableData
                columns={returncolumns}
                data={watch("exchangeorder.products")}
                noHeight
                selectableRows={notVisable}
                onSelectedRowsChange={handleChange}
                fixedHeader={true}
                fixedHeaderScrollHeight={"70vh"}
              />
            </>
          )}


          <Grid my={15}>
            <Grid.Col md={5}>
              <TextAreaField
                label="Other Comments or Special Instructions"
                minRows={3}
                maxRows={8}
                control={control}
                name={"comments"}
                readOnly={!notVisable}
              />
              <Box mt={20} ml={5} sx={{ display: "flex" }}>
                {
                  title === "View Delivery Status" && initialValues?.signature_name && (
                    <Box sx={{ display: 'flex', gap: 5 }}>

                      Name :
                      <Text
                        tt="capitalize"
                        fw={"bold"}
                        fz={"lg"}
                        color="font-color.3"
                        mt={4}
                      >
                        {initialValues?.signature_name}
                      </Text>

                    </Box>
                  )
                }
                {
                  title === "View Delivery Status" && initialValues?.signature_image && (
                    <Box ml={20} sx={{ display: 'flex' }} >

                      Signature:
                      <Image
                        className="pointer"
                        fit="contain"
                        ml={5}
                        width={50}
                        height={50}
                        onClick={() => handleSignatureOpen(initialValues?.signature_image)}
                        src={`${process.env.REACT_APP_API_IMAGE_ENDPOINT}${initialValues.signature_image}`}
                      />
                    </Box>
                  )
                }

              </Box>
            </Grid.Col>
            <Grid.Col md={2}>
              {notVisable && title !== "Cancel Order" && (
                <>
                  <TextField
                    label={"Order Verifed By"}
                    control={control}
                    name={"verify_user"}
                    required={true}
                  />
                  <Datepickers
                    name={"delivery_date"}
                    label={"Delivery Date"}
                    control={control}
                  />
                  {status === "order_complete" && (
                    <>
                      {initialValues?.shipping_method !== "Store pickup" && (
                        <TextField
                          label={"Driver Name"}
                          control={control}
                          required={true}
                          name={"driver"}
                        />
                      )}
                      <Button
                        mt={15}
                        onClick={() => setDocumentPopup(true)}
                        leftIcon={<Image src={uploadIcon} width={"15px"} />}
                        variant="outline"
                        type="button"
                      >
                        {watch("documents")
                          ? `${watch("documents").length} File Selected`
                          : "Upload Document"}
                      </Button>
                    </>
                  )}
                </>
              )}
            </Grid.Col>
            <Grid.Col offsetMd={1} md={4}>
              <Grid>
                <Grid.Col md={4}>
                  <Text fz={"md"}>Total Quantity</Text>
                </Grid.Col>
                <Grid.Col md={4}></Grid.Col>
                <Grid.Col md={4}>
                  <Text fz={"md"}>
                    {!notVisable
                      ? formatNumberComma(totalQuantity)
                      : formatNumberComma(totalQuantityAll)}
                  </Text>
                </Grid.Col>
                <Grid.Col md={4}>
                  <Text fz={"md"}>Total Weight</Text>
                </Grid.Col>
                <Grid.Col md={4}></Grid.Col>
                <Grid.Col md={4}>
                  <Text fz={"md"}>
                    {!notVisable
                      ? formatNumberComma(totalWeight)
                      : status === "order_preparation" ||
                        status === "generate_packing_slip" ||
                        status === "order_complete"
                        ? totalWeight_preparation &&
                        formatNumberComma(totalWeight_preparation)
                        : status === "back_order_preparation" ||
                          status === "back_order_dispatch"
                          ? totalWeight_preparation &&
                          formatNumberComma(totalWeight_preparation)
                          : formatNumberComma(totalWeightAll)}
                    lbs
                  </Text>
                </Grid.Col>
                {initialValues?.exchangeorder && (
                  <>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>Return Total</Text>
                    </Grid.Col>
                    <Grid.Col md={4}></Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>
                        {formatCurrencyComma(
                          initialValues?.exchangeorder?.refund_amount
                        )}
                      </Text>
                    </Grid.Col>
                  </>
                )}
                {enable_tax && (
                  <>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>Sub Total</Text>
                    </Grid.Col>
                    <Grid.Col md={4}></Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>
                        {formatCurrencyComma(watch("amount"))}
                      </Text>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>Carry In Service</Text>
                    </Grid.Col>
                    <Grid.Col md={4}></Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>
                        {formatCurrencyComma(watch("carry_in_service"))}
                      </Text>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>Savings</Text>
                    </Grid.Col>
                    <Grid.Col md={4}></Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>
                        {formatCurrencyComma(watch("savings"))}
                      </Text>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>Shipping Cost</Text>
                    </Grid.Col>
                    <Grid.Col md={4}></Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>
                        {formatCurrencyComma(watch("shipping_cost"))}
                      </Text>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>Tax</Text>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>{watch("product_tax")?.label}</Text>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>
                        {formatCurrencyComma(watch("total_tax"))}
                      </Text>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>Total</Text>
                    </Grid.Col>
                    <Grid.Col md={4}></Grid.Col>
                    <Grid.Col md={4}>
                      <Text fz={"md"}>
                        {formatCurrencyComma(watch("grand_total"))}
                      </Text>
                    </Grid.Col>
                  </>
                )}
              </Grid>
            </Grid.Col>
          </Grid>

          {initialValues?.delivery_document?.length !== 0 &&
            status === "delivery_complete" && (
              <Grid>
                {initialValues?.driver_name && (
                  <Flex>
                    <Text fw={"bold"} fz={"lg"} color="font-color.3">
                      Driver Name:
                    </Text>
                    <Text pl={5} fw={"normal"} fz={"lg"} color="font-color.3">
                      {initialValues?.driver_name}
                    </Text>
                  </Flex>
                )}
                <Grid.Col md={6}>
                  <Table highlightOnHover withBorder withColumnBorders>
                    <thead className="dashboard-table">
                      <tr>
                        <th>SI No</th>
                        <th>Document</th>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="dashboard-table-body">
                      {initialValues?.delivery_document.map(
                        (element, index) => (
                          <tr className="dashboard-table-row">
                            <td className="dashboard-table-body">
                              {index + 1}
                            </td>
                            <td className="dashboard-table-body">
                              <Flex
                                direction={"column"}
                                align={"center"}
                                p={"sm"}
                              >
                                {element?.document &&
                                  typeof element?.document === "string" &&
                                  element?.document?.slice(
                                    element?.document?.lastIndexOf(".") + 1
                                  ) === "pdf" ? (
                                  <embed
                                    fit="contain"
                                    src={`${process.env.REACT_APP_API_IMAGE_ENDPOINT}${element.document}`}
                                    type="application/pdf"
                                    width="100"
                                    className="pointer"
                                    height="50"
                                  />
                                ) : element &&
                                  element?.name?.slice(
                                    element.name.lastIndexOf(".") + 1
                                  ) === "pdf" ? (
                                  <iframe
                                    fit="contain"
                                    type="application/pdf"
                                    title="PDF Viewer"
                                    width="100"
                                    height={"50"}
                                    src={URL.createObjectURL(element)}
                                  />
                                ) : element?.document && element?.document?.slice(element?.document?.lastIndexOf(".") + 1) === "docx"
                                  ? (
                                    <img
                                      fit="contain"
                                      height="50"
                                      width="100"
                                      src={docLogo}
                                      alt="DOCX Icon"
                                    />
                                  )

                                  : (
                                    <Image
                                      className="pointer"
                                      fit="contain"
                                      height="50"
                                      width="100"
                                      onClick={() =>
                                        handleImageClick(element.document)
                                      }
                                      src={
                                        element.document
                                          ? `${process.env.REACT_APP_API_IMAGE_ENDPOINT}${element.document}`
                                          : URL.createObjectURL(element)
                                      }
                                    />
                                  )}
                              </Flex>
                            </td>
                            <td className="dashboard-table-body">
                              {element.document}
                            </td>
                            <td className="dashboard-table-body">
                              <Flex gap={4} align={"center"}>
                                <Image
                                  className="pointer"
                                  onClick={() =>
                                    handleDownload(
                                      `${process.env.REACT_APP_API_IMAGE_ENDPOINT}${element.document}`,
                                      element.document?.split(".")[0]
                                    )
                                  }
                                  width={"1rem"}
                                  src={downloadIcon}
                                />
                              </Flex>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Grid.Col>
              </Grid>
            )}

          {view_order_detail && (
            <>
              <Tabs
                classNames={classes}
                variant="outline"
                defaultValue={"delivery_detail"}
                orientation="horizontal"
              >
                <Tabs.List>
                  <Tabs.Tab value={"delivery_detail"}>
                    Delivery Details
                  </Tabs.Tab>
                  <Tabs.Tab value={"packing_slip"}>Packing Slip</Tabs.Tab>
                  <Tabs.Tab value={"back_order"}>Back Order</Tabs.Tab>
                  <Tabs.Tab value={"return_order"}>Return Order</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value={"delivery_detail"}>
                  <Table highlightOnHover withBorder withColumnBorders>
                    <thead className="dashboard-table">
                      <tr>
                        <th>Delivery ID</th>
                        <th>Order Verified By</th>
                        <th>Delivery Verified By</th>
                        <th>Driver Name</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody className="dashboard-table-body">
                      {initialValues?.deliveysorders?.map((element, index) => (
                        <tr className="dashboard-table-row">
                          <td className="dashboard-table-body">
                            <Text
                              className="pointer"
                              onClick={() =>
                                navigate(
                                  `/menu/order/delivery-status-view/${element.id}?complete_order=true&status=${substatus}&id=${paramsId?.id}&cusid=${customerIdlink}&orderstat=${orderstatus}`
                                )
                              }
                              color="font-color.4"
                            >
                              {element.delivery_no}
                            </Text>
                          </td>
                          <td className="dashboard-table-body">
                            {element?.verify_person}
                          </td>
                          <td className="dashboard-table-body">
                            {element?.delivery_create}
                          </td>
                          <td className="dashboard-table-body">
                            {element?.driver}
                          </td>
                          <td className="dashboard-table-body">
                            {element?.comments}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tabs.Panel>
                <Tabs.Panel value={"packing_slip"}>
                  <Table highlightOnHover withBorder withColumnBorders>
                    <thead className="dashboard-table">
                      <tr>
                        <th>Packing Slip ID</th>
                        <th>Order Verified By</th>
                        <th>Delivery Verified By</th>
                        <th>Driver Name</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody className="dashboard-table-body">
                      {initialValues?.deliveysorders?.map((element, index) => (
                        <tr className="dashboard-table-row">
                          <td className="dashboard-table-body">
                            <Text
                              className="pointer"
                              onClick={() =>
                                navigate(
                                  `/menu/order/generate-view/${element.id}?status=${substatus}&id=${paramsId?.id}&cusid=${customerIdlink}&orderstat=${orderstatus}`
                                )
                              }
                              color="font-color.4"
                            >
                              {element.order_no}
                            </Text>
                          </td>
                          <td className="dashboard-table-body">
                            {element?.verify_person}
                          </td>
                          <td className="dashboard-table-body">
                            {element?.delivery_create}
                          </td>
                          <td className="dashboard-table-body">
                            {element?.driver}
                          </td>
                          <td className="dashboard-table-body">
                            {element?.comments}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tabs.Panel>
                <Tabs.Panel value={"back_order"}>
                  <Table highlightOnHover withBorder withColumnBorders>
                    <thead className="dashboard-table">
                      <tr>
                        <th>Back Order </th>
                        <th>Order No</th>
                        <th>Created By</th>
                        <th>Created at</th>
                        <th>Order Status</th>
                      </tr>
                    </thead>
                    <tbody className="dashboard-table-body">
                      {initialValues?.backorder?.map((element, index) => (
                        <tr key={index} className="dashboard-table-row">
                          <td className="dashboard-table-body">
                            <Text
                              className="pointer"
                              onClick={() =>
                                navigate(
                                  `/menu/order/back-order-view/${element.id}?status=${substatus}&id=${paramsId?.id}&cusid=${customerIdlink}&orderstat=${orderstatus}`
                                )
                              }
                              color="font-color.4"
                            >
                              {element?.order_no}
                            </Text>
                          </td>
                          <td className="dashboard-table-body">
                            {element?.invoice_no}
                          </td>
                          <td className="dashboard-table-body">
                            {element?.backorder_create}
                          </td>
                          <td className="dashboard-table-body">
                            {convertTimeCanadianPos(element?.created_at)}
                          </td>
                          <td className="dashboard-table-body">
                            {element?.status === "Merged"
                              ? element?.status +
                              " to " +
                              element?.merged_order_no
                              : element?.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tabs.Panel>
                <Tabs.Panel value={"return_order"}>
                  <Table highlightOnHover withBorder withColumnBorders>
                    <thead className="dashboard-table">
                      <tr>
                        <th>Order No</th>
                        <th>Return Id</th>
                        <th>Reason</th>
                        <th>Adjustment Amount</th>
                        <th>Sub Total</th>
                        <th>Tax</th>
                        <th>Grand Total</th>
                      </tr>
                    </thead>
                    <tbody className="dashboard-table-body">
                      {initialValues?.returnorder?.map((element, index) => (
                        <tr key={index} className="dashboard-table-row">
                          <td className="dashboard-table-body">
                            <Text
                              className="pointer"
                              onClick={() =>
                                navigate(
                                  `/menu/order/return-order-view/${element.id}?status=${substatus}&id=${paramsId?.id}&cusid=${customerIdlink}&orderstat=${orderstatus}`
                                )
                              }
                              color="font-color.4"
                            >
                              {initialValues?.order_no}
                            </Text>
                          </td>
                          <td className="dashboard-table-body">
                            <Text
                              className="pointer"
                              onClick={() =>
                                navigate(
                                  `/menu/order/return-order-view/${element.id}?status=${substatus}&id=${paramsId?.id}&cusid=${customerIdlink}&orderstat=${orderstatus}`
                                )
                              }
                              color="font-color.4"
                            >
                              {element?.return_id}
                            </Text>
                          </td>
                          <td className="dashboard-table-body">
                            {element?.reason}
                          </td>
                          <td className="dashboard-table-body">
                            {formatCurrencyComma(element.adjustment_amount)}
                          </td>
                          <td className="dashboard-table-body">
                            {formatCurrencyComma(element.return_amount)}
                          </td>
                          <td className="dashboard-table-body">
                            {formatCurrencyComma(element.tax)}
                          </td>
                          <td className="dashboard-table-body">
                            {formatCurrencyComma(element.refund_amount)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tabs.Panel>
              </Tabs>
            </>
          )}
          {(view_invoice || statuslocation === "TransactionSummaryOrders") && (
            <>
              <Grid>
                <Grid.Col span={4}>
                  <Text fw={"bold"} mt={statuslocation === "TransactionSummaryOrders" ? 5 : 0}> {
                    val !== "unpaid" && (`Payment History`)
                  }</Text>
                </Grid.Col>
              </Grid>
              <Grid>
                {
                  val !== "unpaid" && (
                    <Grid.Col span={8}>
                      <Table highlightOnHover withBorder withColumnBorders>
                        <thead className="dashboard-table">
                          <tr>
                            <th>Payment Date</th>
                            <th>Payment Type</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Balance Amount Given</th>
                          </tr>
                        </thead>
                        <tbody className="dashboard-table-body">
                          {initialValues?.transcation?.map((element, index) => (
                            <tr key={index} className="dashboard-table-row">
                              <td className="dashboard-table-body">
                                {element?.created_date}
                              </td>
                              <td className="dashboard-table-body">
                                {formatStatus(element?.payment_type)}
                              </td>
                              <td className="dashboard-table-body">
                                {element?.payment_type === "term"
                                  ? "-"
                                  : formatStatus(element?.status)}
                              </td>
                              <td className="dashboard-table-body">
                                {formatCurrencyComma(element?.amount)}
                              </td>
                              <td className="dashboard-table-body">
                                {formatCurrencyComma(element?.balance_round_cash_given)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Grid.Col>
                  )
                }
              </Grid>
              <Grid p={5}>
                <Text fw={"bold"}> Balance amount to be Paid : </Text>
                <Text> {formatCurrencyComma(initialValues?.balance)}</Text>
              </Grid>
            </>
          )}
          {notVisable && title !== "Cancel Order" && (
            <Button
              loading={isSubmitting}
              onClick={handleSubmit(handleFormSumbit)}
            >
              {title !== "Complete The Order" &&
                title !== "Generate Packing Slip" &&
                "Create"}{" "}
              {title}
            </Button>
          )}
        </Paper>
      </form>
      {isModalOpen.model && (
        <ImagePopup
          opened={isModalOpen.model}
          onClose={handleCloseModal}
          src={`${process.env.REACT_APP_API_IMAGE_ENDPOINT}${isModalOpen.data}`}

        />
      )}
      {isDeliveryModalOpen.model && (
        <DatePopup
          opened={isDeliveryModalOpen.model}
          data={isDeliveryModalOpen}
          handleFormSubmit={(data) => handleDateFormSubmit(data)}
          close={() => {
            setIsDeliveryModalOpen({
              ...isDeliveryModalOpen,
              model: false,
            });
          }}
        />
      )}
      {documentPopup && (
        <MultipleDocumentPopup
          name="documents"
          opened={documentPopup}
          close={() => setDocumentPopup(false)}
          initialValue={watch("documents")}
          handleUploadDocments={(data) => handleUploadDocments(data)}
        />
      )}
      {sendEmailModel.model && (
        <SendEmailPopup
          close={() =>
            setSendEmailModel({
              model: false,
              data: null,
            })
          }
          initailValue={sendEmailModel.data}
          opened={sendEmailModel.model}
          handleSendEmailSumbit={(data) => handleSendEmail(data)}
        />
      )}
      {editAddressModel.model && (
        <SelectFieldPopup
          opened={editAddressModel.model}
          close={() => {
            setEditAddressModel({
              ...editAddressModel,
              model: false,
            });
          }}
          option={shippingOption}
          type={"selectField"}
          name={"shipping_id"}
          label={"Shipping Address"}
          customer_id={initialValues?.customer_id}
          handleAddItem={(data) => handleChangeAddress(data)}
          essentialApi={() => essentialApi(initialValues?.customer_id)}
        />
      )}
    </>
  );
};

export default OrderViewSection;
