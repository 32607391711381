import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "utils/api";



export const Loomisget = createAsyncThunk(
    "loomis-get",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.get(`/loomis/getLoomisNewOrdersList`,  { params: bodyParams })
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 

export const LoomisManifestget = createAsyncThunk(
    "loomismanifest-get",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.get('/loomis/loomisProcessedOrdersList',  { params: bodyParams })
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 

export const LoomisGenrateManifest = createAsyncThunk(
    "loomis-generate-manifest",
    async(formData, thunkAPI) => {
        try {
            const response = await API.post(`/loomis/generateManifest`,  formData)
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 

export const LoomisManifestList = createAsyncThunk(
    "loomis-manifest-list",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.get('loomis/loomisManifestList',  { params: bodyParams })
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 


export const LoomisPickupList = createAsyncThunk(
    "loomis-pickup-list",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.get('loomis/loomisPickupsList',  { params: bodyParams })
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 


export const LoomisTrackingList = createAsyncThunk(
    "loomiss-Track-list",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.get('/loomis/loomisTrackingShipmentList',  { params: bodyParams })
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 


export const LoomisPickupListCancel = createAsyncThunk(
    "loomis-pickup-list-Cancel",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.get('loomis/loomisCanceledPickupsList', { params: bodyParams })
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 


export const LoomisAlldata = createAsyncThunk(
    "loomis-all-data",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.get('loomis/loomisAllOrdersList', { params: bodyParams })
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 


export const LoomisPost = createAsyncThunk(
    "loomis-post",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.post('/loomis/processSelectedLoomisOrders', bodyParams)
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 

export const LoomisCancelPost = createAsyncThunk(
    "loomisCancel-post",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.post('/loomis/cancelLoomisShipment', bodyParams)
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 

export const LoomisGenerateBillPost = createAsyncThunk(
    "loomisGenerateBill-post",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.post('/loomis/generateShipmentLabel', bodyParams)
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 

export const LoomisDownloadManifest = createAsyncThunk(
    "loomiss-download-post",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.post('/loomis/viewdownloadManifest', bodyParams)
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 

export const LoomisPickupDays = createAsyncThunk(
    "loomiss-pickup-post",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.post('/loomis/pickupDays', bodyParams)
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 


export const LoomisSchedulePickupPost = createAsyncThunk(
    "loomiss-schedulepickup-post",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.post('/loomis/schedulePickup', bodyParams)
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 

export const LoomisPickupCancel = createAsyncThunk(
    "loomiss-pickup-post-cancel",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.post('/loomis/cancelPickup', bodyParams)
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 

export const LoomisTrackPost = createAsyncThunk(
    "loomiss-Track-post",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.post('loomis/trackPickup', bodyParams)
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 

export const LoomisPickupHandOverPost = createAsyncThunk(
    "loomiss-HandOver-post",
    async(bodyParams, thunkAPI) => {
        try {
            const response = await API.post('loomis/loomisPickupHandOver', bodyParams)
            return response
        } catch(error) {
          return thunkAPI.rejectWithValue(error)
        }
     
    }
) 


// 'loomis/loomisTrackingShipmentList
