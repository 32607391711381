import { createSlice } from "@reduxjs/toolkit"
import { LoomisAlldata, LoomisCancelPost, LoomisDownloadManifest, LoomisGenerateBillPost, LoomisGenrateManifest, Loomisget, LoomisManifestget, LoomisManifestList, LoomisPickupCancel, LoomisPickupDays, LoomisPickupHandOverPost, LoomisPickupList, LoomisPickupListCancel, LoomisPost, LoomisSchedulePickupPost, LoomisTrackingList, LoomisTrackPost } from "redux/service/settingsService/loomiesService"


const initialState ={
    loomisgetRes: { data: [], isFetching: false, error: null},
    loomispostRes: { data: [], isFetching: false, error: null},
    loomisManifestgetRes: { data: [], isFetching: false, error: null},
    loomisCancelPostRes: {data: [], isFetching: false, error: null},
    loomisGenerateBillPostRes: {data:[], isFetching: false, error: null},
    loomisGenrateManifestRes: {data:[], isFetching: false, error: null},
    loomisManifestListRes: {data:[], isFetching: false, error: null},
    loomisDownloadManifestRes: {data:[], isFetching: false, error: null},
    loomisPickupDaysRes: {data: [], isFetching: false, error: null},
    loomisschedulepickupRes:  {data: [], isFetching: false, error: null},
    loomisPickupsListRes: {data: [], isFetching: false, error: null},
    loomisPickupCancelRes:{data: [], isFetching: false, error: null},
    loomisPickupListCancelRes: {data: [], isFetching: false, error: null},
    loomisTrackinglistRes:  {data: [], isFetching: false, error: null},
    loomisTrackingPostRes: {data: [], isFetching: false, error: null},
    loomisHandOverPostRes: {data: [], isFetching: false, error: null},
    loomisAllDataRes: {data: [], isFetching: false, error: null}
}

export const loomisListSlice = createSlice({
    name: "loomisListSlice",
    initialState,
    extraReducers: (builder) => {
        const asyncActionCases = [
            {api: Loomisget, name: "loomisgetRes"},
            {api: LoomisPost, name: "loomispostRes"},
            {api: LoomisManifestget, name: "loomisManifestgetRes"},
            {api: LoomisCancelPost, name: "loomisCancelPostRes"},
            {api:LoomisGenerateBillPost, name: "loomisGenerateBillPostRes"},
            {api: LoomisGenrateManifest, name: "loomisGenrateManifestRes"},
            {api: LoomisManifestList, name: "loomisManifestListRes"},
            {api: LoomisDownloadManifest, name: "loomisDownloadManifestRes"},
            {api: LoomisPickupDays, name: "loomisPickupDaysRes"},
            {api: LoomisSchedulePickupPost, name:"loomisschedulepickupRes"},
            {api: LoomisPickupList, name: "loomisPickupsListRes"},
            {api: LoomisPickupCancel, name: "loomisPickupCancelRes"},
            {api: LoomisPickupListCancel, name: "loomisPickupListCancelRes"},
            {api: LoomisTrackingList, name: "loomisTrackinglistRes"},
            {api: LoomisTrackPost, name: "loomisTrackingPostRes"},
            {api: LoomisPickupHandOverPost, name: 'loomisHandOverPostRes'},
            {api: LoomisAlldata, name: "loomisAllDataRes"}
        ];
        asyncActionCases.map((asyncAction) => {
            builder
              .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
                state[asyncAction.name].isFetching = false;
                state[asyncAction.name].data = payload;
              })
              .addCase(asyncAction.api.pending, (state) => {
                state[asyncAction.name].isFetching = true;
                state[asyncAction.name].error = null;
              })
              .addCase(asyncAction.api.rejected, (state, { payload }) => {
                state[asyncAction.name].isFetching = false;
                state[asyncAction.name].error = payload;
              });
          });
    }
})

export const {loomisListSliceDispatch} = loomisListSlice.actions